export default [
  {
    id: 'harakeke-2025',
    name: 'Harakeke 2025'
  },
  {
    id: 'tohora-2025',
    name: 'Tohorā 2025'
  },
  {
    id: 'whai-piwakawaka-2024',
    name: 'Whai/Pīwakawaka 2024'
  },
  {
    id: 'kahikatea-2024',
    name: 'Kahikatea 2024'
  },
  {
    id: 'karaka-2024',
    name: 'Karaka 2024'
  },
  {
    id: 'harakeke-2024',
    name: 'Harakeke 2024'
  },
  {
    id: 'tohora-2024',
    name: 'Tohorā 2024'
  }
]
