import React, { useState } from 'react';
import { CircleInfo } from '@styled-icons/fa-solid/CircleInfo';

const styles = {
  tooltipContainer: {
    position: 'relative',     
    marginRight: '8px'     
  },
  tooltip: {
    position: 'absolute',     
    zIndex: 1000,           
    backgroundColor: '#333', 
    color: 'white',         
    padding: '8px 12px',    
    borderRadius: '4px',    
    fontSize: '14px',       
    top: '70%',              
    right: 'calc(100% + 15px)', 
    transform: 'translateY(-50%)', 
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    width: '300px',
  },
  arrow: {
    position: 'absolute',     
    right: '-5px',           
    top: '50%',              
    transform: 'translateY(-50%) rotate(45deg)', 
    width: '10px',           
    height: '10px',          
    backgroundColor: '#333',
  },
  icon: {
    width: '20px',           
    height: '20px',          
    color: '#000',         
    cursor: 'pointer',       
    marginTop: '8px' 
  }
};

const TooltipBox = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      style={styles.tooltipContainer}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div style={styles.tooltip}>
          {text}
          <div style={styles.arrow} />
        </div>
      )}
    </div>
  );
};

const Tooltip = () => {
  return (
    <TooltipBox text="Selecting your cohort will set the links to your bootcamp github challenges">
      <CircleInfo style={styles.icon} />
    </TooltipBox>
  );
};

export default Tooltip;