import React, { useContext } from 'react'

import cohorts from '../../cohorts'
import CohortSelectionContext from './Context'
import Tooltip from './Tooltip'

const CohortSelectionDropdown = () => {
  const {selectedCohort, changeCohort} = useContext(CohortSelectionContext)

  return (
    <div style={{display:'flex', alignContent:'center', justifyContent:'right'}}>
      <Tooltip />
      <select
        aria-label="Select your cohort"
        value={selectedCohort}
        onChange={changeCohort}
        className='cohort-dropdown'>
        <option value=''>Select your cohort</option>
        {cohorts.map(cohort => (
          <option
            key={cohort.id}
            value={cohort.id}>
            {cohort.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CohortSelectionDropdown
